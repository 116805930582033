import { Observable } from "rxjs";
import { RespInterface, RespListInterface } from "@common/ports/interfaces";

import { InstitutionInterface } from "@institution/ports/interfaces";

export abstract class InstitutionAbstraction {
  abstract list(query: string): Observable<RespListInterface<InstitutionInterface>>;
  abstract create(institution: InstitutionInterface): Observable<RespInterface<InstitutionInterface>>;
  abstract update(institution: InstitutionInterface): Observable<RespInterface<string>>;
  abstract read(id: string): Observable<RespInterface<InstitutionInterface>>;
  abstract delete(id: string): Observable<RespInterface<InstitutionInterface>>;
}
